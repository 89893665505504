"use client";
import React from "react";
import { Metadata } from "next";

import { Sections, ErrorBlock } from "@/shared/components";

export const generateMetadata = (): Metadata => {
  const description = "Make Software Selection Easier";
  const title = "INKHUB | The Best Resource for Software Selection";

  const img = `${process.env.NEXT_PUBLIC_BASE_URL}/icon.png`;
  return {
    title,
    description,
    keywords: " INKHUB, Resource page",
    referrer: "origin-when-cross-origin",
    generator: "Next.js",

    openGraph: {
      type: "website",
      url: process.env.NEXT_PUBLIC_BASE_URL,
      title,
      description,
      images: [
        {
          url: img,
          width: 1200,
          height: 630,
          alt: description,
        },
      ],
    },

    authors: [
      {
        name: "INKHUB",
        url: process.env.NEXT_PUBLIC_BASE_URL,
      },
    ],

    twitter: {
      card: "summary_large_image",
      site: "@inkhub",
      creator: "@inkhub",
      images: [
        {
          url: img,
          width: 1200,
          height: 630,
          alt: description,
        },
      ],
      title,
      description,
    },

    alternates: {
      canonical: process.env.NEXT_PUBLIC_BASE,
    },
  };
};

export default function NotFound() {
  return (
    <Sections>
      <ErrorBlock
        message="Sorry, the page you are looking for cannot be found"
        submessage="This page might have been removed or the URL may be incorrect"
      />
    </Sections>
  );
}
